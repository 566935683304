// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ArrowFatLeft,
  ArrowFatRight,
  Calendar,
  CheckCircle,
  Clock,
  Function,
  ListBullets,
  NumberCircleOne,
  TextAlignLeft,
  TextT,
} from "@phosphor-icons/react";
import { NinoxField } from "../ninox-types";

export const ArcBotFieldItem = ({ field }: { field: NinoxField }) => (
  <div className="flex items-center gap-2 p-2 text-sm rounded-sm cursor-default bg-slate-100">
    <div className="icon" title={field?.base}>
      {field.base === "string" && (
        <TextT size={22} className="text-custom-purple" weight="fill" />
      )}

      {field.base === "boolean" && (
        <CheckCircle className="text-custom-purple" weight="fill" size={22} />
      )}
      {field.base === "date" && (
        <Calendar size={22} className="text-custom-purple" weight="fill" />
      )}
      {field.base === "rev" && (
        <ArrowFatLeft size={22} className="text-custom-purple" weight="fill" />
      )}
      {field.base === "ref" && (
        <ArrowFatRight size={22} className="text-custom-purple" weight="fill" />
      )}
      {field.base === "choice" && (
        <ListBullets size={22} className="text-custom-purple" weight="fill" />
      )}
      {field.base === "files" && (
        <ListBullets size={22} className="text-custom-purple" weight="fill" />
      )}
      {field.base === "number" && (
        <NumberCircleOne
          size={22}
          className="text-custom-purple"
          weight="fill"
        />
      )}
      {field.base === "time" && (
        <Clock size={22} className="text-custom-purple" weight="fill" />
      )}
      {field.base === "fn" && (
        <Function size={22} className="text-custom-purple" weight="fill" />
      )}
    </div>
    <span title={field?.caption} className="truncate">
      {field?.caption}
    </span>
  </div>
);
