/* eslint-disable @typescript-eslint/indent */
/**
 * v0 by Vercel.
 * @see https://v0.dev/t/lvB2P5gomzx
 */

// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowUp } from "@phosphor-icons/react";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import untruncateJson from "untruncate-json";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { ChatHistory } from "./ChatHistory";
import { Feedback } from "./Feedback";
import { MyContext } from "../../App";
import { ArcBotTableItem } from "../../components/arcbot-table-item";
import { Header } from "../../components/Header";
import { NinoxDatabaseInput } from "../../components/ninox-database-input";
import { PoliciesDialog } from "../../components/policies-dialogue";
import { Avatar, AvatarFallback } from "../../components/ui/avatar";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import LottieAnimation from "../../components/ui/loadingBubble";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../components/ui/resizable";
import { Textarea } from "../../components/ui/textarea";
import { addRevFields } from "../../ninox-client";
import {
  NinoxTable,
  NinoxTableSchema,
  NinoxTableSchemaUpdate,
  NinoxTableUpdate,
} from "../../ninox-types";
import { Conversation, SignedInUser } from "../../types";

// eslint-disable-next-line import/no-extraneous-dependencies

const setSearchParam = (param: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(param, value);
  window.history.pushState({}, "", url.toString());
};

export const ArcBotAnswerWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div className="flex gap-4 space-x-2 align-top prompt-user-output-item items-top">
    <Avatar className="bg-primary">
      <AvatarFallback>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 256 256"
        >
          <path d="M208,144a15.78,15.78,0,0,1-10.42,14.94l-51.65,19-19,51.61a15.92,15.92,0,0,1-29.88,0L78,178l-51.62-19a15.92,15.92,0,0,1,0-29.88l51.65-19,19-51.61a15.92,15.92,0,0,1,29.88,0l19,51.65,51.61,19A15.78,15.78,0,0,1,208,144ZM152,48h16V64a8,8,0,0,0,16,0V48h16a8,8,0,0,0,0-16H184V16a8,8,0,0,0-16,0V32H152a8,8,0,0,0,0,16Zm88,32h-8V72a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0V96h8a8,8,0,0,0,0-16Z"></path>
        </svg>
      </AvatarFallback>
    </Avatar>
    <div className="flex flex-col flex-grow gap-10">
      <div className="flex flex-col prompt-output-header">
        <p className="font-semibold prompt-user-name">arcBot</p>
      </div>
      <div className="prompt-output-body">{children}</div>
    </div>
  </div>
);

export const ArcBotAppRoute = "/";

export function ArcBotApp() {
  const [prompt, setPrompt] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseHasError, setResponseHasError] = useState(false);
  const [chatHistory, setChatHistory] = useState<Conversation[]>([]);
  const [jsonIsValid, setJsonIsValid] = useState<{
    isValid: boolean;
    errorMessage?: string;
  }>({ isValid: true });
  const [rating, setRating] = useState<boolean>();
  const [comment, setComment] = useState<string>();
  const [submitted, setSubmitted] = useState(false);
  const [userSessions, setUserSessions] = useState<string[]>([]);
  const [sessionId, setSessionId] = useState<string>(uuidv4());

  const location = useLocation();
  const navigate = useNavigate();

  const { sessionId: sessionIdParam } = useParams<{ sessionId: string }>();

  const {
    ninoxTables,
    setLoginOpen,
    setNinoxTables,
    signedInUser,
    stage,
    apiClient,
  } = useContext(MyContext);

  useEffect(() => {
    const url = new URL(window.location.href);
    const promptParam = url.searchParams.get("prompt");

    if (promptParam) {
      setPrompt(promptParam);
    }
  }, []);

  const updateChatHistory = async () => {
    const userEvents = await apiClient.getUserEvents({
      sessionId: sessionIdParam ?? "",
    });
    console.log(`userEvents=${JSON.stringify(userEvents)}`);
    const sessionChatHistory: Conversation[] = userEvents.data.map((event) => ({
      user: event.userInput ?? undefined,
      ...(event.arcBotAnswer && {
        arcbot: {
          answer: event.arcBotAnswer,
        },
      }),
    }));
    setChatHistory(sessionChatHistory);
  };

  useEffect(() => {
    const localStorageAcceptedPolicies = localStorage.getItem("acceptedTerms");
    setAcceptedTerms(localStorageAcceptedPolicies === "true");
  }, []);

  useEffect(() => {
    if (signedInUser) {
      if (sessionId) {
        void (async () => {
          const sessions = await apiClient.getUserSessions();
          console.log(`sessions=${JSON.stringify(sessions)}`);
          setUserSessions(
            sessions.data.map((userSession) => userSession.sessionId)
          );
          void updateChatHistory();
        })();
      } else {
        setSessionId(sessionIdParam ?? uuidv4());
        navigate(`/s/${sessionId}${location.search}`);
      }
    }
  }, [signedInUser, sessionId]);

  useEffect(() => {
    outputEndRef.current?.scrollIntoView({
      behavior: "smooth" as ScrollBehavior,
    }); // Fix type error
    if (!loading) {
      checkIfJsonIsValid(answer);
    }
  }, [chatHistory, loading, answer]);

  const checkIfJsonIsValid = (json: string) => {
    try {
      const data = JSON.parse(json);
      // If the above line doesn't throw an error, the JSON is valid

      const validationResult = z.record(NinoxTableSchemaUpdate).safeParse(data);
      if (!validationResult.success) {
        setJsonIsValid({
          isValid: false,
          errorMessage: validationResult.error.message,
        });
      } else {
        setJsonIsValid({ isValid: true });
      }
    } catch (err) {
      // The JSON is invalid
      setJsonIsValid({ isValid: false, errorMessage: (err as any).message });
    }
  };

  const fetchArcbot = async (p: string) => {
    setSearchParam("prompt", p);

    setAnswer("");
    let currentAnswer = "";
    setLoading(true);

    try {
      const response = await apiClient.askArcBot(
        p,
        ninoxTables,
        sessionId,
        stage
      );

      if (response.status !== 200) {
        setLoading(false);
        throw new Error(response.statusText);
      }

      const reader = response.body?.getReader();

      const readData = async () => {
        const read = await reader?.read();
        if (!read) return;
        if (read.done) {
          console.log("Stream complete");
        } else {
          console.log("read chunk");
          setAnswer(
            (prevAnswer) =>
              `${prevAnswer}${new TextDecoder().decode(read.value)}`
          );
          currentAnswer += new TextDecoder().decode(read.value);
          await readData();
        }
      };
      await readData();

      setLoading(false);
      return currentAnswer;
    } catch (error) {
      console.error(error);
      setResponseHasError(true);
      setLoading(false);
    }
    return "";
  };

  const untruncateJsonAnswer = untruncateJson(answer);
  console.log(`untruncateJsonAnswer: ${untruncateJsonAnswer}`);

  let parsedJson;
  if (untruncateJsonAnswer !== "") {
    console.log("try parsing answer");
    try {
      parsedJson = JSON.parse(untruncateJsonAnswer);
    } catch (e) {
      // eslint-disable-next-line no-console
      // console.log(e);
    }
  }

  const tables = untruncateJsonAnswer
    ? NinoxTableSchemaUpdate.safeParse(untruncateJsonAnswer) && parsedJson
      ? Object.entries(parsedJson)
      : undefined
    : undefined;

  const outputEndRef = useRef<HTMLDivElement>(null);

  const setMergeStatusInChatHistory = (merged: boolean) => {
    const lastConversation = chatHistory[chatHistory.length - 1].arcbot;
    if (lastConversation) {
      setChatHistory([
        ...chatHistory.slice(0, -1),
        {
          arcbot: {
            answer: lastConversation.answer,
            merged: false,
          },
        },
      ]);
    }
  };
  const feedbackInput = document.getElementById(
    "feedback"
  ) as HTMLTextAreaElement;
  const [termsOpen, setTermsOpen] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const submit = async () => {
    if (!sessionId) return;
    // show session now in url like chatgpt does
    navigate(`/s/${sessionId}`);
    // if (submitted) {
    setComment(undefined);
    setRating(undefined);
    setSubmitted(false);
    // }

    console.log(`sessionId=${sessionId}`);

    // store userInput in ninox
    await apiClient.saveSessionUserInput({
      userInput: prompt,
      sessionId,
      stage,
    });
    const currentAnswer = await fetchArcbot(prompt);
    const enrichedAnswer = addRevFields(JSON.parse(currentAnswer));
    setChatHistory([
      ...chatHistory,
      {
        user: prompt,
      },
      {
        arcbot: {
          answer: JSON.stringify(enrichedAnswer),
        },
      },
    ]);
    // store arcBotAnswer in ninox
    await apiClient.saveSessionArcBotAnswer({
      arcBotAnswer: enrichedAnswer,
      sessionId,
      stage,
    });
  };

  if (!sessionId) return <div>404</div>;

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-row flex-grow overflow-hidden">
        <ResizablePanelGroup
          className="flex flex-row flex-grow overflow-hidden"
          direction="horizontal"
        >
          {signedInUser && (
            <ResizablePanel defaultSize={5}>
              <div className="w-full overflow-auto border rounded-md">
                Sessions:
                <ul className="divide-y">
                  {userSessions.map((session) => (
                    <li>
                      <Link
                        className="block p-4 hover:bg-gray-100 dark:hover:bg-gray-800"
                        onClick={updateChatHistory}
                        to={"/s/" + session}
                      >
                        {session.substring(0, 4)}...
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </ResizablePanel>
          )}
          {signedInUser && <ResizableHandle withHandle />}
          <ResizablePanel className="flex flex-col flex-1 p-10 overflow-hidden overflow-x-hidden content-left">
            <div className="flex flex-col flex-grow h-full gap-10 p-0 m-0 overflow-x-hidden overflow-y-scroll prompt-output-container">
              {
                // if the chat history is empty and arcBot didn't start answering
                chatHistory.length === 0 && !loading && !answer && (
                  <div className="flex flex-col justify-center h-full overflow-hidden flex-flow align-center">
                    <LottieAnimation />
                    <div className="grid items-center justify-center w-full grid-cols-12 gap-4 p-4 examples">
                      <Badge
                        className="items-center w-full col-span-7 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                        onClick={() => {
                          const badgeText =
                            "Create a crm for my surfing school with 5 tables and 5 fields each table";
                          setPrompt(badgeText);
                        }}
                      >
                        <div className="w-full text-center truncate">
                          🏄‍♂️ Create a crm for my surfing school with 5 tables
                          and 5 fields each table
                        </div>
                      </Badge>
                      <Badge
                        onClick={() => {
                          const badgeText =
                            "I want to organize the todos of my team";
                          setPrompt(badgeText);
                        }}
                        className="items-center w-full col-span-5 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                      >
                        <div className="w-full text-center truncate">
                          ✅ I want to organize the todos of my team
                        </div>
                      </Badge>
                      <Badge
                        onClick={() => {
                          const badgeText =
                            "Change the adress field a table an connect it";
                          setPrompt(badgeText);
                        }}
                        className="items-center w-full col-span-4 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                      >
                        <div className="w-full text-center truncate">
                          🔁 Change the adress field a table an connect it
                        </div>
                      </Badge>
                      <Badge
                        onClick={() => {
                          const badgeText =
                            "Create 10 tables and a least 5 fields each to organize the production company that produces cars";
                          setPrompt(badgeText);
                        }}
                        className="items-center w-full col-span-8 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                      >
                        <div className="w-full text-center truncate">
                          🚗 Create 10 tables and a least 5 fields each to
                          organize the production company that produces cars
                        </div>
                      </Badge>
                      <Badge
                        onClick={() => {
                          const badgeText =
                            "Change the adress field to several fields";
                          setPrompt(badgeText);
                        }}
                        className="items-center w-full col-span-6 col-start-4 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                      >
                        <div className="w-full text-center truncate">
                          ➕ Change the adress field to several fields
                        </div>
                      </Badge>
                    </div>
                  </div>
                )
              }
              {chatHistory.length > 0 && (
                <div>
                  <ChatHistory chatHistory={chatHistory} />{" "}
                  {!loading && (
                    <div className="pl-14">
                      <Feedback
                        rating={rating}
                        setRating={setRating}
                        comment={comment}
                        setComment={setComment}
                        submitted={submitted}
                        setSubmitted={setSubmitted}
                        sessionId={sessionId}
                      />
                    </div>
                  )}
                </div>
              )}
              {responseHasError && (
                <ArcBotAnswerWrapper>
                  <div className="flex gap-2">
                    <Badge variant="destructive">Error...</Badge>
                    <Button
                      onClick={() => {
                        try {
                          void fetchArcbot(prompt);
                        } catch (error) {
                          setResponseHasError(false);
                        }
                      }}
                    >
                      Retry?
                    </Button>
                  </div>
                </ArcBotAnswerWrapper>
              )}
              {
                // wait for Bedrock to start responding
                loading && answer === "" && (
                  <ArcBotAnswerWrapper>
                    <p className="prompt-message">Thinking...</p>
                    <div
                      className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  </ArcBotAnswerWrapper>
                )
              }
              {
                // Bedrock is responding but not finished yet
                loading && answer != "" && (
                  <div className="flex flex-col gap-4">
                    <ArcBotAnswerWrapper>
                      <div
                        className="grid w-100"
                        style={{
                          gridTemplateColumns:
                            "repeat(auto-fill, minmax(250px, 1fr))",
                          justifyContent: "center",
                          gridAutoRows: "auto",
                          gridTemplateRows: "auto",
                          gap: "10px",
                        }}
                      >
                        {untruncateJsonAnswer &&
                          NinoxTableSchemaUpdate.safeParse(
                            untruncateJsonAnswer
                          ) &&
                          tables?.map(([key, value]) => (
                            <ArcBotTableItem
                              key={key}
                              table={{ [key]: value as any }}
                            />
                          ))}
                        {parsedJson === undefined && answer}
                      </div>
                    </ArcBotAnswerWrapper>
                  </div>
                )
              }
              {
                // Bedrock finished response
                !loading && parsedJson && (
                  <div className="flex items-center justify-between gap-4">
                    <div className="flex flex-col gap-3 pl-14 prompt-merge-action">
                      {!jsonIsValid.isValid && (
                        <Label className="text-red-500 ">{`JSON not valid! Error: ${jsonIsValid.errorMessage}`}</Label>
                      )}
                      <div className="flex gap-2">
                        <Label>Merge with database?</Label>
                      </div>
                      <div className="flex gap-2">
                        <Button
                          className="bg-transparent border-2 text-custom-purple border-custom-purple hover:bg-custom-purple-100 hover:border-custom-purple-hover"
                          onClick={() => {
                            setMergeStatusInChatHistory(false);
                            setAnswer("");
                            setPrompt("");
                          }}
                        >
                          No
                        </Button>
                        <Button
                          onClick={() => {
                            if (!signedInUser) {
                              setLoginOpen(true);
                              return;
                            }
                            const newDatabase: Record<
                              string,
                              NinoxTableUpdate
                            > = {
                              ...ninoxTables,
                              ...JSON.parse(untruncateJsonAnswer),
                            };
                            setMergeStatusInChatHistory(true);
                            setNinoxTables(addRevFields(newDatabase));
                            setAnswer("");
                            setPrompt("");
                          }}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }

              <div ref={outputEndRef} />
            </div>
            <div className="relative mt-6 prompt-input-container">
              <Button
                disabled={prompt.trim() === ""}
                className="absolute right-2"
                style={{ bottom: "5px" }}
                onClick={async (event) => {
                  if (prompt.trim() !== "") {
                    await submit();
                  }
                }}
              >
                <ArrowUp size={20} color="#ffffff" weight="fill" />
              </Button>
              <Textarea
                rows={1}
                className="min-h-[50px] pt-4 pr-10 resize-none max-h-10"
                placeholder="Beschreibe die Felder und Tabellen für deine Datenbank..."
                value={prompt}
                onFocus={() => {
                  if (!acceptedTerms) setTermsOpen(true);
                }}
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
                onKeyDown={async (event) => {
                  if (
                    event.key === "Enter" &&
                    !event.shiftKey &&
                    prompt.trim() !== ""
                  ) {
                    await submit();
                  }
                }}
              />
              <PoliciesDialog
                termsOpen={termsOpen}
                setTermsOpen={setTermsOpen}
              />
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel>
            <div className="flex-1 h-full p-0 overflow-auto content-right bg-slate-100">
              <NinoxDatabaseInput />
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </div>
  );
}
