/* eslint-disable @typescript-eslint/indent */
import { motion } from "framer-motion";
import { Dispatch, useContext, useEffect, useRef, useState } from "react";
import { z } from "zod";
import { ArcBotAnswerWrapper } from "./ArcBotApp";
import { MyContext } from "../../App";
import { ArcBotTableItem } from "../../components/arcbot-table-item";
import PromptUserOutputItem from "../../components/prompt-user-output-item";
import {
  NinoxTable,
  NinoxTableSchema,
  NinoxTableSchemaUpdate,
} from "../../ninox-types";
import { Conversation } from "../../types";

const saveJsonParse = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return undefined;
  }
};

export const ChatHistory = ({
  chatHistory,
}: {
  chatHistory: Conversation[];
}) => {
  const { signedInUser } = useContext(MyContext);
  return (
    <div className="flex flex-col gap-10">
      {chatHistory.map((conversation, index) => (
        <div className="flex flex-col gap-4 pb-10" key={index}>
          {conversation.user && (
            <PromptUserOutputItem
              avatarType="user"
              promptUserName={signedInUser?.userName ?? "You"}
              promptUserInitials={signedInUser?.userInitials ?? "Y"}
              promptUserMessage={conversation.user ?? ""}
            />
          )}
          {conversation.arcbot && (
            <ArcBotAnswerWrapper>
              <div
                className="grid w-100"
                style={{
                  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                  justifyContent: "center",
                  gridAutoRows: "auto",
                  gridTemplateRows: "auto",
                  gap: "10px",
                }}
              >
                {z
                  .record(NinoxTableSchemaUpdate)
                  .safeParse(saveJsonParse(conversation.arcbot.answer)).success
                  ? Object.entries(
                      JSON.parse(conversation.arcbot.answer) as NinoxTable
                    )?.map(([key, value]) => (
                      <ArcBotTableItem
                        key={key as any}
                        table={{ [key]: value as any }}
                      />
                    ))
                  : conversation.arcbot.answer}
              </div>
            </ArcBotAnswerWrapper>
          )}
        </div>
      ))}
    </div>
  );
};
