import { DotsNine } from "@phosphor-icons/react";
import { ArcBotFieldItem } from "./arcbot-field-item";
import { NinoxTableUpdate } from "../ninox-types";

export const ArcBotTableItem = ({
  table,
}: {
  table: Record<string, NinoxTableUpdate>;
}) => {
  const tableValue = Object.values(table)[0];

  if (!tableValue) return <div></div>;

  const fields = tableValue.fields;

  return (
    <div
      className={
        "p-6 text-slate-700 bg-white border-slate-100 border-2 rounded-lg gap-4 flex flex-col shadow-xl"
      }
    >
      <div className="flex flex-row items-center gap-2 mb-2">
        <DotsNine size={24} color="#444" />
        <h2 className="text-lg font-bold truncate">{tableValue?.caption}</h2>
      </div>
      <div
        className="grid gap-2"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(110px, 1fr))",
          justifyContent: "center",
          gridAutoRows: "auto",
          gridTemplateRows: "auto",
          gap: "8px",
        }}
      >
        {fields &&
          Object.values(fields).map((field, index) =>
            field ? <ArcBotFieldItem key={index} field={field} /> : <div></div>
          )}
      </div>
    </div>
  );
};
