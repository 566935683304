/* eslint-disable @typescript-eslint/indent */
import { LockKey, Lightbulb, MagicWand } from "@phosphor-icons/react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import Login from "./Login";
import { NinoxConnector } from "./NinoxConnector";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Input } from "./ui/input";
import Logo from "./ui/logo";
import WaitListForm from "./waitlistForm";
import { MyContext } from "../App";
import { ArcBotAppRoute } from "../pages/ArcBotApp/ArcBotApp";
import { FeatureRequestsRoute } from "../pages/FeatureRequests/FeatureRequests";

export const Header = () => {
  const { signedInUser, ninoxTables, stage } = useContext(MyContext);

  const shareUrl = `https://${stage === "dev" ? "app.dev" : "app"}.arcbot.de/?ninoxTables=${encodeURIComponent(JSON.stringify(ninoxTables))}`;

  return (
    <div className="flex flex-row justify-between px-10 py-4 border-b border-custom-gray">
      <div className="flex flex-row items-center gap-10 top-nav">
        <a href={ArcBotAppRoute}>
          <Logo />
        </a>
        <div className="flex flex-row items-center gap-2">
          <a
            href={ArcBotAppRoute}
            className={
              "flex items-center gap-1 font-medium text-sm block p-3 space-y-1 leading-none no-underline transition-colors rounded-md outline-none select-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            }
          >
            <MagicWand size={18} color="#000" weight="fill" /> Builder
          </a>
          <NavLink
            to={FeatureRequestsRoute}
            className={({ isActive }) =>
              isActive
                ? "flex items-center gap-1 font-medium text-sm block p-3 space-y-1 leading-none no-underline transition-colors rounded-md outline-none select-none bg-accent text-accent-foreground"
                : "flex items-center gap-1 font-medium text-sm block p-3 space-y-1 leading-none no-underline transition-colors rounded-md outline-none select-none hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            }
          >
            <Lightbulb size={18} color="#000" weight="fill" />
            Feature Requests
          </NavLink>
        </div>
      </div>

      <div className="flex items-center justify-end space-x-4 content">
        <Dialog>
          <DialogTrigger asChild>
            <Button disabled={!ninoxTables} variant="outline" className="gap-3">
              Share
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Share Ninox Tables</DialogTitle>
            </DialogHeader>
            <Input value={shareUrl} />
            <Button onClick={() => navigator.clipboard.writeText(shareUrl)}>
              Copy
            </Button>
          </DialogContent>
        </Dialog>
        <NinoxConnector />
        {!signedInUser && (
          <Dialog>
            <DialogTrigger asChild>
              <Button
                className="gap-3 text-white bg-black hover:bg-black"
                onClick={() => {
                  //   setAnswer('');
                  //   setPrompt('');
                }}
              >
                <LockKey weight="fill" /> Get Early Access
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle className="flex flex-row gap-3">
                  <LockKey weight="fill" />
                  Test arcBot with Early Access.
                </DialogTitle>
                <DialogDescription>
                  In Early Access, you can connect and test arcBot with your own
                  database. Sign up now for the waitlist.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className="w-full">
                <WaitListForm />
              </DialogFooter>
            </DialogContent>
          </Dialog>
        )}
        <Login />
      </div>
    </div>
  );
};
