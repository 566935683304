import axios, { AxiosInstance } from "axios";
import {
  NinoxDatabase,
  NinoxDatabaseSchema,
  NinoxField,
  NinoxTable,
  NinoxTableUpdate,
} from "./ninox-types";

export const addRevFields = (tables: Record<string, NinoxTableUpdate>) => {
  // add rev fields
  let tablesWithRev = tables;
  // find ref fields
  for (const tableEntry of Object.entries(tables)) {
    const table = tableEntry[1];
    if (!table) continue;
    for (const fieldEntry of Object.entries(table.fields ?? {})) {
      const field = fieldEntry[1];
      if (!field) continue;
      if (field.base === "ref" && field.refFieldId && field.refTypeId) {
        console.log(`found ref`);
        // add rev field
        // tablesWithRev = {};
        const revFields = tablesWithRev[field.refTypeId]?.fields;
        if (revFields) {
          revFields[fieldEntry[0]] = {
            base: "rev",
            caption: `${table.caption}`,
            refFieldId: fieldEntry[0],
            refTypeId: tableEntry[0],
          };
        }

        const refFields = tablesWithRev[tableEntry[0]]?.fields;
        if (refFields) {
          refFields[fieldEntry[0]] = {
            base: "ref",
            caption: `${field.caption}`,
            refFieldId: fieldEntry[0],
            refTypeId: field.refTypeId,
          };
        }
      }
    }
  }

  return tablesWithRev;
};

class NinoxClient {
  private httpClient: AxiosInstance;

  constructor(
    ninoxUrl: string,
    apiKey: string,
    teamId: string,
    databaseId: string
  ) {
    this.httpClient = axios.create({
      baseURL: `${ninoxUrl}/v1/teams/${teamId}/databases/${databaseId}`,
      // baseURL: `https://arc-rider.ninoxdb.de/v1/teams/${teamId}/databases/${databaseId}`,
      headers: {
        Authorization: `Bearer ${apiKey}`,
        ["Content-Type"]: "application/json",
      },
    });
  }

  // async getTables() {
  //   try {
  //     const response = await this.httpClient.get<NinoxTable[]>('/tables');
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error fetching tables:', error);
  //     throw error;
  //   }
  // }

  async getDatabase() {
    // try {
    const database = (await this.httpClient.get<NinoxDatabase>("/")).data;
    console.log(`database= ${JSON.stringify(database)}`);
    // don't include dashboard table
    // delete schema.types.A;
    return database;
    // } catch (error) {
    //   console.error('Error fetching tables:', error);
    //   throw error;
    // }
  }

  async updateTables(tables: Record<string, NinoxTableUpdate>) {
    console.log(`tables= ${JSON.stringify(tables)}`);
    // try {
    // get current schema
    const schema = (await this.httpClient.get<NinoxDatabaseSchema>("/schema"))
      .data;
    console.log(`schema= ${JSON.stringify(schema)}`);
    if (schema.seq === undefined) {
      throw new Error("schema seq shouldn't be empty!");
    }

    // filter fields which not exist in ninox db
    const tablesFiltered = Object.entries(tables)
      // .filter((t) => t[1] !== null) //&& schema.types[t[0]] !== undefined
      .map((t) => {
        if (t[1] === null) return t;
        Object.entries(t[1]?.fields ?? {});
        const tableFiltered: typeof t = [
          t[0],
          {
            ...t[1],
            fields: Object.fromEntries(
              Object.entries(t[1]?.fields ?? {}).filter(
                (f) =>
                  f[1] !== null ||
                  (f[1] === null && schema.types[t[0]]?.fields?.[f[0]])
              )
            ),
          },
        ];
        return tableFiltered;
      });

    // Convert the filtered tables back into an object
    const tableFilteredRecord = Object.fromEntries(tablesFiltered);
    // const tableFilteredRecord = tables;

    const payload = {
      // result: {
      ...schema,
      types: addRevFields(tableFilteredRecord),
      // },
    };

    console.log(`payload= ${JSON.stringify(payload)}`);

    // override types/tables but keep the Dashboard table
    const newSchema = (
      await this.httpClient.patch<NinoxDatabaseSchema>("/schema", payload)
    ).data;

    console.log(`newSchema= ${JSON.stringify(newSchema)}`);

    return newSchema.types;

    // } catch (error) {
    //   console.error('Error fetching tables:', error);
    //   throw error;
    // }
  }
}

export default NinoxClient;
