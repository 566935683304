import axios from "axios";
import { WaitlistInput } from "./components/waitlistForm";
import { NinoxTable, NinoxTableUpdate } from "./ninox-types";
import {
  FeatureRequest,
  UserVoting,
} from "./pages/FeatureRequests/FeatureRequestList";
import { NewNinoxEnvironment, NinoxEnvironment, SignedInUser } from "./types";

export class ApiClient {
  constructor(
    private ninoxPrivateLambdaUrl: string,
    private ninoxPublicLambdaUrl: string,
    private arcBotLambdaUrl: string,
    private signedInUser: SignedInUser | undefined
  ) {}

  setSignedInUser(signedInUser: SignedInUser) {
    this.signedInUser = signedInUser;
  }

  async askArcBot(
    userInput: string,
    ninoxTables: Record<string, NinoxTableUpdate> | undefined,
    sessionId: string | undefined,
    stage: string
  ) {
    // looks like we can't use axios here as axios doesn't seem to support a stream reader
    const response = await fetch(this.arcBotLambdaUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userInput,
        ninoxTables,
        userEmail: this.signedInUser?.userEmail,
        stage,
        sessionId,
      }),
    });

    return response;
  }

  private async sendRequest<T>(privateRequest: boolean, payload: string) {
    if (privateRequest && !this.signedInUser)
      throw new Error("You must be signed in");

    const headers = privateRequest
      ? { Authorization: `Bearer ${this.signedInUser?.idToken}` }
      : {};

    const response = await axios.post<T>(
      privateRequest ? this.ninoxPrivateLambdaUrl : this.ninoxPublicLambdaUrl,
      {
        query: payload,
      },
      {
        headers,
      }
    );

    console.log(response);
    return response;
  }

  private checkIfUserOwnedEnvironment = (environmentId: number) => {
    const userHasEnvironment = this.signedInUser?.ninoxEnvironments?.find(
      (environment) => environment.environmentId === environmentId
    );
    if (!userHasEnvironment)
      throw new Error("You are not authorized to edit this environment");
  };

  async editNinoxEnvironment({
    environmentId,
    teamId,
    cloudUrl,
    apiKey,
    databaseId,
  }: NinoxEnvironment) {
    this.checkIfUserOwnedEnvironment(environmentId);

    return this.sendRequest(
      true,
      `editNinoxEnvironment({"environmentId":${environmentId}, "cloudUrl": "${cloudUrl}", "apiKey": "${apiKey}", "teamId": "${teamId}", "databaseId": "${databaseId}"})`
    );
  }

  async createNinoxEnvironment({
    cloudUrl,
    apiKey,
    teamId,
    databaseId,
  }: NewNinoxEnvironment) {
    return this.sendRequest(
      true,
      `createNinoxEnvironment({"userEmail": "${this.signedInUser?.userEmail}", "cloudUrl": "${cloudUrl}", "apiKey": "${apiKey}", "teamId": "${teamId}", "databaseId": "${databaseId}"})`
    );
  }

  async getUserSession({ sessionId }: { sessionId: string }) {
    return this.sendRequest(
      true,
      `getUserSession({"userEmail": "${this.signedInUser?.userEmail}", "sessionId": "${sessionId}"})`
    );
  }

  async getUserSessions() {
    return this.sendRequest<{ timestamp: string; sessionId: string }[]>(
      true,
      `getUserSessions(${JSON.stringify({
        userEmail: this.signedInUser?.userEmail,
      })})`
    );
  }

  async selectNinoxEnvironment({ environmentId }: { environmentId: number }) {
    this.checkIfUserOwnedEnvironment(environmentId);

    return this.sendRequest(
      true,
      `selectNinoxEnvironment({"environmentId":${environmentId}})`
    );
  }

  async joinWaitlist(formState: WaitlistInput) {
    return this.sendRequest<{ body: string }>(
      false,
      `joinWaitlist(${JSON.stringify(formState)})`
    );
  }

  async createUserFeedback({
    comment,
    rating,
    sessionId,
    stage,
  }: {
    comment: string | undefined;
    rating: boolean | undefined;
    sessionId: string;
    stage: string;
  }) {
    return this.sendRequest(
      false,
      `createUserFeedback({"comment": "${comment}", "rating": ${rating}, "sessionId": "${sessionId}", "stage": "${stage}", "userEmail": "${
        this.signedInUser?.userEmail
      }", "timestamp": "${new Date().toISOString()}"})`
    );
  }

  async saveSessionUserInput({
    userInput,
    sessionId,
    stage,
  }: {
    userInput: string;
    sessionId: string;
    stage: string;
  }) {
    return this.sendRequest<{ body: string }>(
      false,
      `createUserEvents(${JSON.stringify({
        userEmail: this.signedInUser?.userEmail,
        sessionId,
        stage,
        logs: [
          {
            timestamp: new Date().toISOString(),
            userInput,
          },
        ],
      })})`
    );
  }

  async saveSessionArcBotAnswer({
    arcBotAnswer,
    sessionId,
    stage,
  }: {
    arcBotAnswer: any;
    sessionId: string;
    stage: string;
  }) {
    return this.sendRequest<{ body: string }>(
      false,
      `createUserEvents(${JSON.stringify({
        userEmail: this.signedInUser?.userEmail,
        sessionId,
        stage,
        logs: [
          {
            timestamp: new Date().toISOString(),
            arcBotAnswer,
          },
        ],
      })})`
    );
  }

  async getUserEvents({ sessionId }: { sessionId: string }) {
    return this.sendRequest<
      {
        ninoxId: string;
        userInput: string | undefined;
        arcBotAnswer: string | undefined;
        userId: string;
      }[]
    >(
      true,
      `getUserEvents(${JSON.stringify({
        userEmail: this.signedInUser?.userEmail,
        sessionId,
      })})`
    );
  }

  async createVoting({ featureRequestId }: { featureRequestId: number }) {
    return this.sendRequest<{ body: string }>(
      true,
      `createVoting({ "id": ${featureRequestId}, "userEmail": "${this.signedInUser?.userEmail}" })`
    );
  }

  async createFeatureRequest({
    title,
    description,
  }: {
    title: string | undefined;
    description: string | undefined;
  }) {
    return this.sendRequest(
      true,
      `createFeatureRequest({ "userEmail": "${this.signedInUser?.userEmail}", "title": "${title}", "description": "${description}" })`
    );
  }

  async loadTicketPage(userEmail?: string) {
    return this.sendRequest<{
      votings: UserVoting[];
      tickets: FeatureRequest[];
    }>(
      false,
      `loadTicketPage(${`{"userEmail": "${
        this.signedInUser?.userEmail ?? userEmail ?? ""
      }"}`})`
    );
  }
}
