import { CircleNotch } from "@phosphor-icons/react";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useContext, useState, useEffect } from "react";
import { PoliciesDialog } from "./policies-dialogue";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";
import { MyContext } from "../App";

export type WaitlistInput = {
  email: string;
  name: string;
  company: string;
  hasNinoxDatabase: boolean;
  acceptedTerms: boolean;
};

const WaitListForm = () => {
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  useEffect(() => {
    const localStorageAcceptedPolicies = localStorage.getItem("acceptedTerms");
    setAcceptedTerms(localStorageAcceptedPolicies === "true");
  }, []);
  const [formState, setFormState] = useState<WaitlistInput>({
    email: "",
    name: "",
    company: "",
    hasNinoxDatabase: false,
    acceptedTerms: false,
  });

  const { apiClient } = useContext(MyContext);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSwitchChange = (newValue: boolean) => {
    setFormState({
      ...formState,
      hasNinoxDatabase: newValue,
    });
  };

  const handleAcceptTermsChange = (newValue: boolean) => {
    setFormState({
      ...formState,
      acceptedTerms: newValue,
    });
    setAcceptedTerms(newValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(formState);
    // send formState to waitlist lambda
    const response = await apiClient.joinWaitlist(formState);

    console.log(response);
    setIsLoading(false);

    const { userId, message } = JSON.parse(response.data.body);

    console.log(`userId=${userId}`);
    // redirect to /waitinglist
    if (response.status === 200) {
      if (message === "Email already subscribed") {
        alert("Email already subscribed!");
      } else {
        window.location.href = `/waitlistRequested?userId=${userId}`;
      }
    }
  };

  return (
    <form
      className="flex flex-col items-center w-full gap-3 mt-4"
      onSubmit={handleSubmit}
    >
      <Input
        name="email"
        type="email"
        placeholder="Email"
        value={formState.email} // Binden des Wertes an den Zustand
        onChange={handleInputChange}
      />
      <Input
        name="name"
        type="text" // Typ korrigiert
        placeholder="Name"
        value={formState.name} // Binden des Wertes an den Zustand
        onChange={handleInputChange}
      />
      <Input
        name="company"
        type="text" // Typ korrigiert
        placeholder="Company"
        value={formState.company} // Binden des Wertes an den Zustand
        onChange={handleInputChange}
      />
      <div className="flex items-center w-full mb-5 space-x-2">
        <Switch
          id="ninox-user"
          checked={formState.hasNinoxDatabase}
          onCheckedChange={handleSwitchChange}
        />
        <Label htmlFor="ninox-user">I have a Ninox Database</Label>
      </div>
      <div className="flex items-center w-full mb-5 space-x-2">
        <Switch
          id="terms"
          checked={formState.acceptedTerms}
          onCheckedChange={handleAcceptTermsChange}
        />
        <div className="flex flex-col w-full items-left">
          <Label htmlFor="terms">I agree to the Terms & Conditions</Label>
          <Label htmlFor="terms" className="text-xs font-light">
            By subscribing to our Early Access list you agree to our{" "}
            <a
              className="underline"
              href="/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
            .
          </Label>
        </div>
      </div>

      {isLoading ? (
        <Button disabled className="w-full gap-3 bg-black">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ ease: "linear", duration: 0.6, repeat: Infinity }}
          >
            <CircleNotch size={20} />
          </motion.div>
          Please wait
        </Button>
      ) : (
        <Button
          className="w-full bg-black"
          type="submit"
          disabled={!acceptedTerms}
        >
          Get Early Access
        </Button>
      )}
    </form>
  );
};

export default WaitListForm;
