/**
 * v0 by Vercel.
 * @see https://v0.dev/t/J9DD3QYvnww
 */
// import { Hub } from "@aws-amplify/core";

import { zodResolver } from "@hookform/resolvers/zod";
import { LockKey, User } from "@phosphor-icons/react";
import { Amplify } from "aws-amplify";
import { fetchAuthSession, signIn, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import { LoadingSpinner } from "./LoadingSpinner";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "./ui/form";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";
import WaitListForm from "./waitlistForm";
import { MyContext } from "../App";
import { TermsRoute } from "../pages/Policies/Terms";

export default function Login() {
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  useEffect(() => {
    const localStorageAcceptedPolicies = localStorage.getItem("acceptedTerms");
    setAcceptedTerms(localStorageAcceptedPolicies === "true");
  });

  const loginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6),
    terms: acceptedTerms
      ? z.boolean()
      : z.boolean().refine((value) => value === true, {
          message: "You must agree to the terms and conditions",
        }),
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [isSwitchOn, setSwitchOn] = useState(false);
  const {
    userPoolId,
    userPoolClientId,
    signedInUser,
    setLoginOpen,
    setSignedInUser,
    loginOpen,
  } = useContext(MyContext);

  const handleAcceptTerms = () => {
    localStorage.setItem("acceptedTerms", "true");
    window.location.reload();
  };
  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
      terms: false,
    },
  });

  useEffect(() => {
    void (async () => {
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolId,
            userPoolClientId,
          },
        },
      });

      // console.log('fetchAuthSession');
      const session = await fetchAuthSession();
      // console.log(`session: ${JSON.stringify(session)}`);
      if (session.tokens?.accessToken && session.tokens?.idToken) {
        console.log(
          `accessToken: ${JSON.stringify(session.tokens?.accessToken)}`
        );
        console.log(`idToken: ${JSON.stringify(session.tokens?.idToken)}`);
        // const currentUser = await getCurrentUser();
        console.log("setSignedInUser");
        setSignedInUser({
          accessToken: session.tokens.accessToken.toString(),
          idToken: session.tokens.idToken.toString(),
          userEmail: session.tokens.idToken.payload.email as string,
          userName: session.tokens.idToken.payload.userName as string,
          userInitials: session.tokens.idToken.payload.userInitials as string,
          acceptedTerms: session.tokens.idToken.payload.acceptedTerms as string,
          selectedUserEnvironmentId: session.tokens.idToken.payload
            .selectedUserEnvironmentId as string,
          ninoxEnvironments: JSON.parse(
            (session.tokens.idToken.payload.ninoxEnvironments as string) ?? "[]"
          ),
        });
        // setSignedInUser(apiClient.signedInUser);
      }
    })();

    const listener = Hub.listen("auth", async ({ payload }) => {
      console.log(`auth payload: ${JSON.stringify(payload)}`);

      switch (payload.event) {
        case "tokenRefresh":
        // break;
        case "signedIn":
          const session = await fetchAuthSession();
          console.log(`session: ${JSON.stringify(session)}`);
          if (session.tokens?.accessToken && session.tokens?.idToken) {
            console.log(
              `accessToken: ${JSON.stringify(session.tokens?.accessToken)}`
            );
            console.log(`idToken: ${JSON.stringify(session.tokens?.idToken)}`);
            // const currentUser = await getCurrentUser();

            setSignedInUser({
              accessToken: session.tokens.accessToken.toString(),
              idToken: session.tokens.idToken.toString(),
              userEmail: session.tokens.idToken.payload.email as string,
              userName: session.tokens.idToken.payload.userName as string,
              acceptedTerms: session.tokens.idToken.payload
                .acceptedTerms as string,
              userInitials: session.tokens.idToken.payload
                .userInitials as string,
              selectedUserEnvironmentId: session.tokens.idToken.payload
                .selectedUserEnvironmentId as string,
              ninoxEnvironments: JSON.parse(
                (session.tokens.idToken.payload.ninoxEnvironments as string) ??
                  "[]"
              ),
            });
            // setSignedInUser(apiClient.signedInUser);

            console.log(`apiClient.signedInUser:${signedInUser}`);
          }
          break;
        case "signedOut":
          setSignedInUser(undefined);
          break;

        case "tokenRefresh_failure":
          break;
        case "signInWithRedirect":
          break;
        case "signInWithRedirect_failure":
          break;
        case "customOAuthState":
          break;
      }
    });

    return () => listener();
  }, []);

  const onSubmit = async (values: z.infer<typeof loginSchema>) => {
    setIsLoading(true);
    try {
      await signIn({
        username: values.email,
        password: values.password,
      });
      setIsLoading(false);
      setLoginOpen(false);
      handleAcceptTerms();
    } catch (error: any) {
      setIsLoading(false);
      console.log(`error: ${JSON.stringify(error)}}`);
      if (
        error.name === "UserNotFoundException" ||
        error.name === "NotAuthorizedException"
      ) {
        setErrorMessage("Email or Password wrong!");
      } else {
        console.log("error signing in", error);
      }
    }
  };

  const doLogout = async () => {
    try {
      await signOut();
      // apiClient.signedInUser = undefined;
      setSignedInUser(undefined);
    } catch (error) {
      console.log("error signing out", error);
      return false;
    }
    return true;
  };

  return (
    <main className="flex items-center justify-center dark:bg-gray-900">
      {signedInUser && (
        <div className="flex gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="flex items-center gap-2 shrink-0">
                <div className="flex flex-col items-end gap-1">
                  <Label>{`${signedInUser?.userName}`}</Label>
                  <Label className="text-xs font-light text-slate-400">
                    Early Access
                  </Label>
                </div>
                <Avatar className="shrink-0">
                  <AvatarImage src="" />
                  <AvatarFallback className="text-white bg-custom-purple">
                    {`${signedInUser?.userInitials}`}
                  </AvatarFallback>
                </Avatar>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-auto">
              <DropdownMenuLabel>
                <div className="flex flex-col gap-1">
                  <Label>My Account</Label>
                  <div className="text-xs font-light">{`${signedInUser?.userEmail}`}</div>
                </div>
              </DropdownMenuLabel>

              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <a className="" href="/terms" target="_blank" rel="noreferrer">
                  Terms & Conditions
                </a>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={async () => {
                  await doLogout();
                }}
              >
                Log out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
      {!signedInUser && (
        <Dialog open={loginOpen} onOpenChange={setLoginOpen}>
          <DialogTrigger asChild>
            <Button className="gap-3 text-black bg-white border-2 border-black hover:bg-black hover:text-white">
              Login
            </Button>
          </DialogTrigger>
          <DialogContent className="w-full max-w-md">
            <DialogHeader>
              <DialogTitle className="text-2xl">Login</DialogTitle>
              <DialogDescription>
                Enter your email and password to login to your account.
              </DialogDescription>
            </DialogHeader>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="email@here"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setErrorMessage("");
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder=""
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setErrorMessage("");
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {!acceptedTerms && (
                  <FormField
                    control={form.control}
                    name="terms"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center justify-between p-3 border rounded-lg shadow-sm">
                        <div className="space-y-0.5">
                          <FormLabel className="text-xs">
                            I agree to the Terms & Conditions
                          </FormLabel>
                          <FormDescription className="text-xs">
                            By logging in you agree to our{" "}
                            <a
                              className="underline"
                              href="/terms"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Terms & Conditions
                            </a>
                            .
                          </FormDescription>
                        </div>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                )}
                {errorMessage && (
                  <div className="text-red-500">{errorMessage}</div>
                )}
                <div className="flex items-center justify-between gap-2">
                  <Button className="w-full" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <div className="flex items-center gap-2">
                        <User weight="fill" />
                        Login{" "}
                      </div>
                    )}
                  </Button>
                  {!signedInUser && (
                    <Button
                      className="w-full gap-3 text-white bg-black hover:bg-black"
                      onClick={() => {
                        setRegisterOpen(true);
                        setLoginOpen(false);
                      }}
                    >
                      <LockKey weight="fill" /> Get Early Access
                    </Button>
                  )}
                </div>
              </form>
            </Form>
            <DialogFooter className="flex items-center justify-between">
              <Dialog>
                <DialogTrigger asChild></DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogDescription>
                      Enter your email below and we'll send you a link to reset
                      your password.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <div className="space-y-2">
                      <Label htmlFor="reset-email">Email</Label>
                      <Input
                        id="reset-email"
                        placeholder="m@example.com"
                        required
                        type="email"
                      />
                    </div>
                  </div>
                  <DialogFooter>
                    <Button type="submit">Send reset link</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
      <Dialog open={registerOpen} onOpenChange={setRegisterOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="flex flex-row gap-3">
              <LockKey weight="fill" />
              Test arcBot with Early Access.
            </DialogTitle>
            <DialogDescription>
              In Early Access, you can connect and test arcBot with your own
              database. Sign up now for the waitlist.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="w-full">
            <WaitListForm />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </main>
  );
}
