/* eslint-disable @typescript-eslint/indent */
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "@radix-ui/react-label";
import { fetchAuthSession } from "aws-amplify/auth";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { LoadingSpinner } from "./LoadingSpinner";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { MyContext } from "../App";
import NinoxClient from "../ninox-client";
import useConfirm from "../utils/useConfirm";

const ninoxDataSchema = z.object({
  // environmentId: z.string().optional(),
  cloudUrl: z
    .string()
    .url()
    .refine((value) => !value.includes(" "), "Should not contain spaces"),
  apiKey: z.string().min(2),
  teamId: z
    .string()
    .min(2)
    .refine((value) => !value.includes(" "), "Should not contain spaces"),
  databaseId: z
    .string()
    .min(2)
    .refine((value) => !value.includes(" "), "Should not contain spaces"),
});

export const NinoxConnector = () => {
  const {
    apiClient,
    databaseConnected,
    setDatabaseConnected,
    setLoginOpen,
    setNinoxTables,
    signedInUser,
    setNinoxDatabaseName,
    ninoxDatabaseName,
  } = useContext(MyContext);

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTestConnection, setIsTestConnection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // if undefined it is a new one
  // const [environmentId, setEnvironmentId] = useState<number>();
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(
    signedInUser?.selectedUserEnvironmentId
  );
  const [databaseName, setDatabaseName] = useState("");

  const { getConfirmation, Confirmation } = useConfirm();

  const form = useForm<z.infer<typeof ninoxDataSchema>>({
    resolver: zodResolver(ninoxDataSchema),
    defaultValues: {
      cloudUrl: "",
      apiKey: "",
      teamId: "",
      databaseId: "",
    },
  });

  useEffect(() => {
    setSelectedEnvironmentId(signedInUser?.selectedUserEnvironmentId);
    if (signedInUser?.ninoxEnvironments) {
      form.reset(
        signedInUser.ninoxEnvironments.find(
          (e) =>
            e.environmentId === Number(signedInUser.selectedUserEnvironmentId)
        ) ?? signedInUser.ninoxEnvironments[0]
      );
    }
    if (!signedInUser) {
      form.reset({ cloudUrl: "", apiKey: "", teamId: "", databaseId: "" });
    }
    // console.log(`signedInUser:${JSON.stringify(signedInUser)}`);
  }, [signedInUser]);

  useEffect(() => {
    void (async () => {
      if (!signedInUser) {
        setDatabaseConnected(false);
        return;
      }
      const { cloudUrl, apiKey, teamId, databaseId } = form.getValues();
      try {
        const ninoxClient = new NinoxClient(
          cloudUrl,
          apiKey,
          teamId,
          databaseId
        );
        const database = await ninoxClient.getDatabase();
        setNinoxDatabaseName(database.settings.name);
        const ninoxTablesImported = database.schema.types;
        if (setNinoxTables) {
          setNinoxTables(ninoxTablesImported);
        }
        setDatabaseConnected(true);
      } catch (error) {
        setDatabaseConnected(false);
        setErrorMessage("Ninox connection failed");
      }
    })();
  }, [form, signedInUser]);

  const onSubmit = async ({
    cloudUrl,
    apiKey,
    teamId,
    databaseId,
  }: z.infer<typeof ninoxDataSchema>) => {
    if (!signedInUser) {
      setLoginOpen(true);
      return;
    }
    if (isTestConnection) {
      setErrorMessage("");
      setDatabaseConnected(false);
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    let ninoxTablesImported;
    try {
      const ninoxClient = new NinoxClient(cloudUrl, apiKey, teamId, databaseId);
      const database = await ninoxClient.getDatabase();
      setDatabaseName(database.settings.name);
      ninoxTablesImported = database.schema.types;
      if (setNinoxTables) {
        setNinoxTables(ninoxTablesImported);
      }
      setDatabaseConnected(true);
      setErrorMessage("");
    } catch (error) {
      setDatabaseConnected(false);
      setErrorMessage("Ninox connection failed");
    }

    // want to edit or add environment
    if (!isTestConnection) {
      if (!ninoxTablesImported) {
        const status = await getConfirmation({
          title: "Attention!",
          message:
            "Are you sure you want to save? Your database connection doesn't seem to work?!",
        });
        if (!status) {
          return;
        }
      }

      setIsLoading(true);

      try {
        if (selectedEnvironmentId) {
          // is edit
          await apiClient.editNinoxEnvironment({
            environmentId: Number(selectedEnvironmentId),
            cloudUrl,
            teamId,
            apiKey,
            databaseId,
          });
          await apiClient.selectNinoxEnvironment({
            environmentId: Number(selectedEnvironmentId),
          });
        } else {
          // is add
          await apiClient?.createNinoxEnvironment({
            cloudUrl,
            apiKey,
            teamId,
            databaseId,
          });
        }
      } catch (error) {
        setErrorMessage("Ninox connection failed");
        return;
      }

      try {
        const session = await fetchAuthSession({ forceRefresh: true });
        console.log(`session: ${JSON.stringify(session)}`);
      } catch (error) {
        console.error("Error refetching auth token", error);
      }

      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={async (isOpen) => {
        if (!isOpen && form.formState.isDirty) {
          const status = await getConfirmation({
            title: "Attention!",
            message:
              "You have unsaved changes! Are you sure you want to close this dialog?",
          });
          console.log(`status:${status}`);
          if (status) {
            setOpen(false);
            form.reset();
            return;
          }
          return;
        }
        setOpen(isOpen);
      }}
    >
      <DialogTrigger asChild>
        <Button variant="outline" className="gap-3">
          <div
            className={`w-2 h-2 rounded-full ${
              databaseConnected ? "bg-green-400" : "bg-black"
            }`}
          />
          Ninox Connector
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Ninox Connector</DialogTitle>
        </DialogHeader>
        <Confirmation />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <div className="flex gap-2">
              <Select
                onValueChange={async (value) => {
                  // a new environment is requested
                  if (value === "add") {
                    form.reset({
                      cloudUrl: "",
                      apiKey: "",
                      teamId: "",
                      databaseId: "",
                    });
                    setSelectedEnvironmentId(undefined);
                  } else {
                    form.reset(
                      signedInUser?.ninoxEnvironments.find(
                        (e) => e.environmentId.toString() === value
                      )
                    );
                    setSelectedEnvironmentId(value);
                  }
                }}
                value={selectedEnvironmentId}
                // defaultValue={signedInUser?.selectedUserEnvironmentId}
              >
                <SelectTrigger id="database">
                  <SelectValue placeholder="Environment Index" />
                </SelectTrigger>
                <SelectContent position="popper">
                  {signedInUser?.ninoxEnvironments.map((environment, index) => (
                    <SelectItem
                      key={index}
                      value={environment.environmentId.toString()}
                    >
                      {`database Id:${environment.databaseId}`}
                    </SelectItem>
                  ))}
                  <SelectItem value={"add"}>Add Environment</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <FormField
              control={form.control}
              name="cloudUrl"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Private Cloud URL</FormLabel>
                  <FormControl>
                    <Input
                      placeholder=""
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="apiKey"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ninox API Key</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder=""
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="teamId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Team Id</FormLabel>
                  <FormControl>
                    <Input
                      placeholder=""
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="databaseId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Database Id</FormLabel>
                  <FormControl>
                    <Input
                      placeholder=""
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div>
              <Label>Database Name</Label>
              <div>{ninoxDatabaseName}</div>
            </div>
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            {databaseConnected && (
              <div className="text-green-500 ">Database connected</div>
            )}
            <div className="flex gap-2">
              <Button
                type="submit"
                disabled={isLoading}
                onClick={() => setIsTestConnection(false)}
              >
                {isLoading ? <LoadingSpinner /> : "Save Settings"}
              </Button>
              <Button type="submit" onClick={() => setIsTestConnection(true)}>
                Test Connection
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
